import axios from "axios";

export const API = axios.create({
  baseURL: "https://proxyapi.view123app.com/",
});

// Backend modules to be reused
export const API_MOD = {
  REPORT: "report",
  OPERATOR: "operator",
  AGGREGATOR: "aggregator",
  USER: "user",
};
